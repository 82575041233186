import React from 'react'

// Utils
import { getPathname } from '../../utils'

// Hooks
import { useDictionaryCtx, useLayoutCtx } from '../../hooks/context'

// Components
import { Helmet } from 'react-helmet'

const SEO = ({
  title,
  description,
  image,
  slug,
  isBlog,
  isBlogPost,
  isProduct,
  isPage,
}) => {
  const dictionary = useDictionaryCtx()
  const { node_locale } = useLayoutCtx()
  const ogImage = image || dictionary.seoFallbackImage.file.url
  const seoDescription = description || dictionary.seoFallbackDescription

  return (
    <Helmet>
      {/* General tags */}
      <html lang={node_locale} />
      <title>{title}</title>
      <meta name='description' content={seoDescription} />
      <meta name='image' content={`https:${ogImage}`} />
      <link
        rel='canonical'
        href={`https://cycle.care${getPathname(node_locale, slug)}`}
      />
      {/* OpenGraph tags */}
      <meta
        property='og:url'
        content={`https://cycle.care${getPathname(node_locale, slug)}`}
      />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={seoDescription} />
      <meta property='og:image' content={`https:${ogImage}`} />
      {isPage && <meta property='og:type' content='page' />}
      {isBlog && <meta property='og:type' content='blog' />}
      {isBlogPost && <meta property='og:type' content='article' />}
      {isProduct && <meta property='og:type' content='product' />}
    </Helmet>
  )
}

export default SEO
